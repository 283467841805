import { render } from "@testing-library/react";
import React, { useState, useEffect, useCallback } from "react";
import "../Styling/applepay.css";
import { isSafari } from "react-device-detect";

const merchantIdentity =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "IDdhuVDUcuSbMKK8LodmL4j2"
    : "IDh78D1ZEt9C2gsATdobvEWc";

// ------------------ Start of Apple Pay Session Code if hosted on donation.dot.ngo ------------------

function applePaySession(props) {
  // console.log(props)
  // document.getElementById("mytitle").innerHTML = "hello";
  const applePaySession = new window.ApplePaySession(6, {
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: { label: props.sponsee, amount: props.total },
    requiredBillingContactFields: ["postalAddress", "name", "email", "phone"],
    requiredShippingContactFields: ["postalAddress", "name", "email", "phone"],
  });

  applePaySession.begin();
  // console.log('began apple session');

  applePaySession.onvalidatemerchant = async (event) => {
    // console.log('tyring validate merchant url');
    try {
      var validationURL = event.validationURL;
      let hostname = window.location.hostname.replace(/^(https):\/\//, "");
      // console.log("Validating merchant with url: " + validationURL);

      if (validationURL) {
        // console.log('Validated url');
        var request = JSON.stringify({
          validationURL: validationURL,
          merchantIdentity: merchantIdentity,
          domainName: hostname,
          displayName: props.sponsee,
        });
        // console.log(request);
        let response = await fetch(
          process.env.REACT_APP_APPLE_PAY_CREATE_SESSION_URL,
          {
            method: "POST",
            body: request,
          }
        );
        response = await response.json();
        const sessionDetails = response["sessionDetails"];
        let merchantSession = JSON.parse(sessionDetails);
        applePaySession.completeMerchantValidation(merchantSession);
      } else {
        // console.log("Merchant validation failed");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // step 6 create a payment
  applePaySession.onpaymentauthorized = (event) => {
    // console.log('in step 6');
    try {
      //token received from Apple
      // console.log(event)
      var paymentToken = event.payment;

      if (paymentToken) {
        // console.log("Payment token from Apple: " + paymentToken);
        // send Finix API stringified form of the token
        var stringifiedPaymentToken = JSON.stringify(paymentToken);
        applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
        props.setLoad(true);
        props.makeApplePayPayment(stringifiedPaymentToken);
      } else {
        // console.log("Payment token from Apple is null - payment failed to process.");
        applePaySession.completePayment(applePaySession.STATUS_FAILURE);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

// ------------------ End of Apple Pay Session Code ------------------

// ------------------ React Component ------------------

export default function ApplePay(props) {
  //const [applePayToken, setApplePayToken] = useState('');
  //let applePaySession = null

  // flag alternates between 0 and 1. The value is arbitrary. Whenever it changes, we update the oldApplePayFunction
  // The reason for doing this is because we need to keep track and remove this function before adding the new one every render
  // const [flag, setFlag] = useState(0);
  // const [updateClickHandler, setUpdateClickHandler] = useState(false)

  const [shookHands, setShookHands] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const [timePassed, setTimePassed] = useState(0);

  function handler(event) {
    // handshake handler to verify if apple pay is enabled
    // using UUID to verify same session
    if (
      event.data.type === "scriptHandshake"
      // && event.data.uuid === props.uuid
    ) {
      // console.log('handshake received in checkoutform')
      setShookHands(true);
    }

    // apple pay session token retrieval handler
    // using UUID to verify same session
    if (
      event.data.type === "applePayToken"
      // && event.data.uuid === props.uuid
    ) {
      props.setLoad(true);
      const paymentToken = JSON.parse(event.data.token);
      const shippingInfo = paymentToken.shippingContact;
      // console.log({shippingInfo})
      props.makeApplePayPayment(event.data.token);
    }
  }

  useEffect(() => {
    if (window.location.ancestorOrigins.length == 0) {
      // console.log('not in iframe')
      props.setShowApplePay(true);
    } else {
      // console.log('in iframe')
      setIsIframe(true);
      window.addEventListener("message", handler);

      // console.log('sending message to parent window to initiate handshake')

      // initiate handshake
      window.parent.postMessage(
        {
          type: "iframeHandshake",
          sponsee: props.sponsee,
          total: props.total,
          merchantIdentity: merchantIdentity,
          createApplePaySessionURL:
            process.env.REACT_APP_APPLE_PAY_CREATE_SESSION_URL,
          uuid: props.uuid,
        },
        "*"
      );
      // console.log('sent message')
      return () => {
        window.removeEventListener("message", handler);
      };
    }
  }, []);

  // set showApplePay to true if handshake is successful
  useEffect(() => {
    if (shookHands) {
      console.log("setting showApplePay to true");
      props.setShowApplePay(true);
    }
  }, [shookHands]);

  // If not a safari browser, return an empty div
  if (!isSafari) {
    return <></>;
  }

  // If not in iframe, return button click to trigger apple pay session from react component
  if (!isIframe) {
    return (
      <>
        <button
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#000000",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          className="ApplePayButton"
          onClick={(e) => {
            e.preventDefault();
            applePaySession(props);
          }}
        >
          {/* <svg xmlns="http://www.w3.org/2000/svg" height="50" width="100%" viewBox="-76.79115 -52.55 665.5233 315.3">
            <path d="M93.541 27.1c-6 7.1-15.6 12.7-25.2 11.9-1.2-9.6 3.5-19.8 9-26.1 6-7.3 16.5-12.5 25-12.9 1 10-2.9 19.8-8.8 27.1m8.7 13.8c-13.9-.8-25.8 7.9-32.4 7.9-6.7 0-16.8-7.5-27.8-7.3-14.3.2-27.6 8.3-34.9 21.2-15 25.8-3.9 64 10.6 85 7.1 10.4 15.6 21.8 26.8 21.4 10.6-.4 14.8-6.9 27.6-6.9 12.9 0 16.6 6.9 27.8 6.7 11.6-.2 18.9-10.4 26-20.8 8.1-11.8 11.4-23.3 11.6-23.9-.2-.2-22.4-8.7-22.6-34.3-.2-21.4 17.5-31.6 18.3-32.2-10-14.8-25.6-16.4-31-16.8m80.3-29v155.9h24.2v-53.3h33.5c30.6 0 52.1-21 52.1-51.4s-21.1-51.2-51.3-51.2zm24.2 20.4h27.9c21 0 33 11.2 33 30.9s-12 31-33.1 31h-27.8zm129.8 136.7c15.2 0 29.3-7.7 35.7-19.9h.5v18.7h22.4V90.2c0-22.5-18-37-45.7-37-25.7 0-44.7 14.7-45.4 34.9h21.8c1.8-9.6 10.7-15.9 22.9-15.9 14.8 0 23.1 6.9 23.1 19.6v8.6l-30.2 1.8c-28.1 1.7-43.3 13.2-43.3 33.2 0 20.2 15.7 33.6 38.2 33.6zm6.5-18.5c-12.9 0-21.1-6.2-21.1-15.7 0-9.8 7.9-15.5 23-16.4l26.9-1.7v8.8c0 14.6-12.4 25-28.8 25zm82 59.7c23.6 0 34.7-9 44.4-36.3l42.5-119.2h-24.6l-28.5 92.1h-.5l-28.5-92.1h-25.3l41 113.5-2.2 6.9c-3.7 11.7-9.7 16.2-20.4 16.2-1.9 0-5.6-.2-7.1-.4v18.7c1.4.4 7.4.6 9.2.6z" fill="#fff"/>
            </svg> */}
          <svg
            style={{ display: "block" }}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="100%"
            height="30px"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 512 211"
          >
            <path
              d="M93.552 27.103c-6 7.1-15.602 12.702-25.203 11.901c-1.2-9.6 3.5-19.802 9.001-26.103C83.35 5.601 93.852.4 102.353 0c1 10.001-2.9 19.802-8.8 27.103Zm8.701 13.802c-13.902-.8-25.803 7.9-32.404 7.9c-6.7 0-16.802-7.5-27.803-7.3c-14.301.2-27.603 8.3-34.904 21.202c-15.002 25.803-3.9 64.008 10.601 85.01c7.101 10.401 15.602 21.802 26.803 21.402c10.602-.4 14.802-6.9 27.604-6.9c12.901 0 16.602 6.9 27.803 6.7c11.601-.2 18.902-10.4 26.003-20.802c8.1-11.801 11.401-23.303 11.601-23.903c-.2-.2-22.402-8.7-22.602-34.304c-.2-21.402 17.502-31.603 18.302-32.203c-10.002-14.802-25.603-16.402-31.004-16.802Zm80.31-29.004V167.82h24.202v-53.306h33.504c30.603 0 52.106-21.002 52.106-51.406c0-30.403-21.103-51.206-51.306-51.206h-58.507Zm24.202 20.403h27.903c21.003 0 33.004 11.201 33.004 30.903c0 19.702-12.001 31.004-33.104 31.004h-27.803V32.304ZM336.58 169.019c15.202 0 29.303-7.7 35.704-19.902h.5v18.702h22.403V90.21c0-22.502-18.002-37.004-45.706-37.004c-25.703 0-44.705 14.702-45.405 34.904h21.803c1.8-9.601 10.7-15.902 22.902-15.902c14.802 0 23.103 6.901 23.103 19.603v8.6l-30.204 1.8c-28.103 1.7-43.304 13.202-43.304 33.205c0 20.202 15.701 33.603 38.204 33.603Zm6.5-18.502c-12.9 0-21.102-6.2-21.102-15.702c0-9.8 7.901-15.501 23.003-16.401l26.903-1.7v8.8c0 14.602-12.401 25.003-28.803 25.003Zm82.01 59.707c23.603 0 34.704-9 44.405-36.304L512 54.706h-24.603l-28.503 92.11h-.5l-28.503-92.11h-25.303l41.004 113.513l-2.2 6.901c-3.7 11.701-9.701 16.202-20.402 16.202c-1.9 0-5.6-.2-7.101-.4v18.702c1.4.4 7.4.6 9.201.6Z"
              fill="#fff"
            />
          </svg>
        </button>
      </>
    );
  }

  // If apple pay script is not present and it's embedded as an iframe, return an empty div
  if (!shookHands && isIframe) {
    return <></>;
  }

  // otherwise return apple button (apple pay script is present and is an iframe)
  return (
    <>
      <button
        style={{
          width: "100%",
          height: "50px",
          backgroundColor: "#000000",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        className="ApplePayButton"
        onClick={(e) => {
          e.preventDefault();
          // console.log(window.origin);
          // console.log(window.location.ancestorOrigins[0])
          // console.log('posting message');
          if (props.schedulePaymentShowComponent() === false) {
            // only send message if schedule payment modals are closed
            window.parent.postMessage(
              {
                type: "applePay",
                sponsee: props.sponsee,
                total: props.total,
                merchantIdentity: merchantIdentity,
                createApplePaySessionURL:
                  process.env.REACT_APP_APPLE_PAY_CREATE_SESSION_URL,
                uuid: props.uuid,
              },
              "*"
            );
          }
        }}
      >
        {/* <svg xmlns="http://www.w3.org/2000/svg" height="50" width="100%" viewBox="-76.79115 -52.55 665.5233 315.3">
            <path d="M93.541 27.1c-6 7.1-15.6 12.7-25.2 11.9-1.2-9.6 3.5-19.8 9-26.1 6-7.3 16.5-12.5 25-12.9 1 10-2.9 19.8-8.8 27.1m8.7 13.8c-13.9-.8-25.8 7.9-32.4 7.9-6.7 0-16.8-7.5-27.8-7.3-14.3.2-27.6 8.3-34.9 21.2-15 25.8-3.9 64 10.6 85 7.1 10.4 15.6 21.8 26.8 21.4 10.6-.4 14.8-6.9 27.6-6.9 12.9 0 16.6 6.9 27.8 6.7 11.6-.2 18.9-10.4 26-20.8 8.1-11.8 11.4-23.3 11.6-23.9-.2-.2-22.4-8.7-22.6-34.3-.2-21.4 17.5-31.6 18.3-32.2-10-14.8-25.6-16.4-31-16.8m80.3-29v155.9h24.2v-53.3h33.5c30.6 0 52.1-21 52.1-51.4s-21.1-51.2-51.3-51.2zm24.2 20.4h27.9c21 0 33 11.2 33 30.9s-12 31-33.1 31h-27.8zm129.8 136.7c15.2 0 29.3-7.7 35.7-19.9h.5v18.7h22.4V90.2c0-22.5-18-37-45.7-37-25.7 0-44.7 14.7-45.4 34.9h21.8c1.8-9.6 10.7-15.9 22.9-15.9 14.8 0 23.1 6.9 23.1 19.6v8.6l-30.2 1.8c-28.1 1.7-43.3 13.2-43.3 33.2 0 20.2 15.7 33.6 38.2 33.6zm6.5-18.5c-12.9 0-21.1-6.2-21.1-15.7 0-9.8 7.9-15.5 23-16.4l26.9-1.7v8.8c0 14.6-12.4 25-28.8 25zm82 59.7c23.6 0 34.7-9 44.4-36.3l42.5-119.2h-24.6l-28.5 92.1h-.5l-28.5-92.1h-25.3l41 113.5-2.2 6.9c-3.7 11.7-9.7 16.2-20.4 16.2-1.9 0-5.6-.2-7.1-.4v18.7c1.4.4 7.4.6 9.2.6z" fill="#fff"/>
            </svg> */}
        <svg
          style={{ display: "block" }}
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="100%"
          height="30px"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 512 211"
        >
          <path
            d="M93.552 27.103c-6 7.1-15.602 12.702-25.203 11.901c-1.2-9.6 3.5-19.802 9.001-26.103C83.35 5.601 93.852.4 102.353 0c1 10.001-2.9 19.802-8.8 27.103Zm8.701 13.802c-13.902-.8-25.803 7.9-32.404 7.9c-6.7 0-16.802-7.5-27.803-7.3c-14.301.2-27.603 8.3-34.904 21.202c-15.002 25.803-3.9 64.008 10.601 85.01c7.101 10.401 15.602 21.802 26.803 21.402c10.602-.4 14.802-6.9 27.604-6.9c12.901 0 16.602 6.9 27.803 6.7c11.601-.2 18.902-10.4 26.003-20.802c8.1-11.801 11.401-23.303 11.601-23.903c-.2-.2-22.402-8.7-22.602-34.304c-.2-21.402 17.502-31.603 18.302-32.203c-10.002-14.802-25.603-16.402-31.004-16.802Zm80.31-29.004V167.82h24.202v-53.306h33.504c30.603 0 52.106-21.002 52.106-51.406c0-30.403-21.103-51.206-51.306-51.206h-58.507Zm24.202 20.403h27.903c21.003 0 33.004 11.201 33.004 30.903c0 19.702-12.001 31.004-33.104 31.004h-27.803V32.304ZM336.58 169.019c15.202 0 29.303-7.7 35.704-19.902h.5v18.702h22.403V90.21c0-22.502-18.002-37.004-45.706-37.004c-25.703 0-44.705 14.702-45.405 34.904h21.803c1.8-9.601 10.7-15.902 22.902-15.902c14.802 0 23.103 6.901 23.103 19.603v8.6l-30.204 1.8c-28.103 1.7-43.304 13.202-43.304 33.205c0 20.202 15.701 33.603 38.204 33.603Zm6.5-18.502c-12.9 0-21.102-6.2-21.102-15.702c0-9.8 7.901-15.501 23.003-16.401l26.903-1.7v8.8c0 14.602-12.401 25.003-28.803 25.003Zm82.01 59.707c23.603 0 34.704-9 44.405-36.304L512 54.706h-24.603l-28.503 92.11h-.5l-28.503-92.11h-25.303l41.004 113.513l-2.2 6.901c-3.7 11.701-9.701 16.202-20.402 16.202c-1.9 0-5.6-.2-7.101-.4v18.702c1.4.4 7.4.6 9.201.6Z"
            fill="#fff"
          />
        </svg>
      </button>
    </>
  );
}
