import React, { useState } from "react";
import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";

function StripeExpressCheckout({
  makeGooglePayPayment,
  makeApplePayPayment,
  setStripeWalletAvailable,
  setLoad,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();
  const [visibility, setVisibility] = useState("hidden");

  const onReady = ({ availablePaymentMethods }) => {
    console.log("availablePaymentMethods", availablePaymentMethods);
    if (!availablePaymentMethods) {
      // No buttons will show
      setStripeWalletAvailable(false);
    } else {
      // Optional: Animate in the Element
      setVisibility("initial");
      setStripeWalletAvailable(true);
    }
  };

  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
      phoneNumberRequired: true,
      billingAddressRequired: true,
    };
    resolve(options);
  };

  return (
    <ExpressCheckoutElement
      options={{
        paymentMethods: {
          applePay: "always",
          googlePay: "always",
        },
        layout: {
          overflow: "never",
        },
        buttonType: {
          googlePay: "pay",
        },
      }}
      style={{ visibility: visibility }}
      onReady={onReady}
      onClick={onClick}
      onConfirm={async (paymentData) => {
        setLoad(true);
        console.log("paymentData", paymentData);
        const wallet = paymentData.expressPaymentType.toUpperCase();
        // create payment method from payment data of express checkout
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements: elements,
          params: { billing_details: paymentData.billingDetails },
        });
        console.log("paymentMethod", paymentMethod);
        if (wallet === "GOOGLE_PAY") {
          makeGooglePayPayment(paymentMethod);
        }
        if (wallet === "APPLE_PAY") {
          makeApplePayPayment(paymentMethod);
        }
      }}
    />
  );
}

export default StripeExpressCheckout;
